.select2-user-result {
  em {
    font-weight: bold;
  }
}

div.form-group.has-error {
  .select2-selection__rendered {
    border-color: #a94442;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}
