table.dataTable tbody td {
  padding: 9px 8px;
  height: auto;
}

table.dataTable {
  margin-top: 15px !important;

  th { white-space: nowrap; }

  tr.selected, tr.selected .sorting_1 {
    background-color: rgba(0, 136, 204, 0.8) !important;
  }
}


table.dataTable.collapsed {
  tr td:first-child::before {
    top: 15px !important;
    line-height: 16px !important;
  }

  tr td:first-child {
    padding-left: 30px !important;
  }
}

.dataTables_filter {
  float: right;
}

.dt-bootstrap {
  .pagination {
    margin-top: 10px !important;

    .paginate_button {
      display: inline-block;

      a {
        border: 1px solid #d8e2e7;
        border-right: none;
        color: #0082c6;
        display: inline-block;
        padding: .275rem .75rem;
        font-size: .875rem;
        line-height: 1.5;
      }
    }

    .paginate_button.disabled {
      a {
        opacity: 0.8;
      }
    }

    .paginate_button.active {
      a {
        color: #fff;
        border-color: #00a8ff !important;
        background-color: #00a8ff !important;
      }
    }

    .paginate_button:first-child {
      a {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }

    .paginate_button:last-child {
      a {
        border-right: 1px solid #d8e2e7;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}
