@import '../partials/mixins';
@import '../partials/vars';

/* ==========================================================================
   Files
   ========================================================================== */

.files-manager { .clearfix(); }

.files-manager-side { float: left; width: 168px; position: relative; background: @color-bg-grey-light; border-right: solid 1px @color-border-light; border-radius: 3px 0 0 3px; }
.files-manager-side-title { padding: 15px; font-weight: 600; }
.files-manager-side-list { font-weight: 600; padding: 0 0 5px;
   a { display: block; color: @color-grey-txt; padding: 6px 10px 7px 11px; border-left: solid 4px transparent;
      &:hover, &.active { background-color: @color-bg-grey; color: @color-text; }
      &:hover { border-left-color: @color-bg-grey; }
      &.active { border-left-color: @color-blue; }
   }
}

.files-manager-panel { float: right; width: 100%; margin-left: -168px; }
.files-manager-panel-in { margin-left: 168px; .clearfix(); }

.files-manager-header { border-bottom: solid 1px @color-border-light; padding: 10px 15px; .clearfix();
   .files-manager-header-left { float: left; }
   .files-manager-header-right { float: right; .clearfix(); }

   .btn, .btn-icon { vertical-align: top; }
   .btn { margin: 0 22px 0 0; }
   .btn-icon { height: 38px; padding: 0; background: none; border: none; color: @color-grey-blue-lighter-txt; margin: 0 8px 0 0;
      &:hover { color: @color-blue; }
      .font-icon { vertical-align: middle; position: relative; top: 1px; }

      &.view { color: @color-border;
         &:hover, &.active { color: @color-grey-blue-lighter-txt; }
      }
   }
   .views { float: left; margin: 0 10px 0 0; }

   .search { float: left; width: 216px; position: relative;
      .form-control { padding-right: 35px; }
      .btn-icon { position: absolute; right: 5px; top: -1px; }
   }
}

.files-manager-aside { float: right; width: 280px; position: relative; }
.files-manager-aside-section { border-top: solid 1px @color-border-light; padding: 15px 15px 20px;
   &:first-child { border-top: none; }
   .box-typical-header-sm { padding: 0 0 10px; }
   .info-list { padding: 0 0 10px;
      p { margin: 0 0 6px; }
   }
   .soc { display: inline-block; font-size: 22px; margin: 15px 5px 0 0;
      &:hover { opacity: .7; }
      .font-icon-fb-fill { color: @color-fb; }
      .font-icon-vk-fill { color: @color-vk; }
      .font-icon-ok-fill { color: @color-ok; }
      .font-icon-tw-fill { color: @color-tw; }
      .font-icon-gp-fill { color: @color-gp; }
   }
}

.files-manager-content { float: left; width: 100%; margin-right: -280px; }
.files-manager-content-in { margin-right: 280px; border-right: solid 1px @color-border-light;}

.fm-file-grid { padding: 18px 0 0 18px; .clearfix();  }

.fm-file { float: left; width: 150px; margin: 0 18px 18px 0; border-radius: .25rem; padding: 18px 10px 15px; text-align: center; cursor: default;
   .fm-file-icon { height: 100px; line-height: 100px;
      img { vertical-align: middle; width: 100px; height: 100px; }
   }
   .fm-file-name, .file-size { white-space: nowrap; overflow: hidden; width: 100%; text-overflow: ellipsis; }
   .fm-file-size { color: @color-grey-blue-txt; }

   &:hover,
   &.selected { background-color: @color-bg-grey-light; }
}

@media (max-width: 830px) {
   .files-manager-side { float: none; width: auto; border-right: none; border-bottom-left-radius: 0; border-bottom: solid 1px @color-border-light; }
   .files-manager-panel { float: none; margin-left: 0; }
   .files-manager-panel-in { margin-left: 0; }
}

@media (max-width: @sm-max-width) {
   .files-manager-header {
      .files-manager-header-left,
      .files-manager-header-right { float: none; }
      .files-manager-header-left { padding-bottom: 10px; }
   }

   .files-manager-aside { float: none; width: auto; }
   .files-manager-content { float: none; margin-right: 0; }
   .files-manager-content-in { margin-right: 0; border-right: none; }
}

@media (max-width: 380px) {
   .files-manager-header-left { padding-bottom: 0 !important;
      .btn { display: block; }
   }
}
