@import '../partials/mixins';
@import '../partials/vars';

/* ==========================================================================
   Steps numeric
   ========================================================================== */

.steps-numeric-block {
   .steps-numeric-header { border-bottom: solid 1px @color-border-light; text-align: center; }
   .steps-numeric-header-in { color: @color-grey-blue-lighter-txt; display: table; width: 100%; border-collapse: collapse; margin: 0 0 -2px;
      ul { display: table-row; list-style: none; }
      li { display: table-cell; vertical-align: middle; font-size: 0; line-height: 0; padding: 0 10px; }
      .item { display: inline-block; height: 50px; line-height: 50px; overflow: hidden; font-size: 1rem; border-bottom: solid 2px transparent; }
      .num { border: solid 1px @color-grey-blue-lighter-txt; border-radius: 50%; display: inline-block; vertical-align: middle; width: 24px; height: 24px; text-align: center; line-height: 22px; position: relative; top: -1px; margin: 0 4px 0 0; }

      .active { color: @color-link;
         .item { border-bottom-color: @color-link; }
         .num { border-color: @color-link; }
      }
   }
   .steps-numeric-inner { padding: 30px 20px 25px;
      .form-control { width: 100%; max-width: 280px; }
   }
   .steps-numeric-title { font-size: 1.25rem /*20/16*/; margin: 0 0 20px; }
   .steps-numeric-footer { border-top: solid 1px @color-border-light; text-align: center; line-height: 50px; font-weight: 600;
      .tbl-cell { border-left: solid 1px @color-border-light; padding: 0 20px;
         &:first-child { border-left: none; }
      }
      a { color: @color-grey-blue-txt; }
   }
}

/* ==========================================================================
   Steps icon
   ========================================================================== */

.steps-icon-block { padding: 30px 20px 25px; text-align: center; .clearfix();
   .steps-numeric-title { font-size: 1.25rem /*20/16*/; margin: 0 0 15px; }
   .form-control { width: 100%; max-width: 280px; margin: 0 auto; }
   .btn { min-width: 100px; margin: 14px 5px 5px; }
}

.steps-icon-progress { overflow: hidden;
   ul { .clearfix(); text-align: center; margin: 0 0 25px; }
   li { float: left; width: 25%; position: relative;
      &:before { content: ''; display: block; width: 100%; height: 8px; background: #e1e4e9; position: absolute; left: -50%; top: 10px; }
      &:first-child:before { display: none; }
   }
   .icon { width: 28px; height: 28px; margin: 0 auto 5px; border-radius: 50%; text-align: center; line-height: 28px; background: #e1e4e9; color: @color-grey-blue-lighter-txt; position: relative; z-index: 5;
      .font-icon { position: relative; top: 1px; }
   }
   .caption { display: inline-block; color: @color-grey-blue-lighter-txt; }

   .active {
      &:before { background-color: @color-link; }
      .icon { background-color: @color-link; color: #fff; }
      .caption { color: @color-text; }
   }
}



