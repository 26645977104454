@charset "UTF-8";

@font-face {
	font-family: 'Glyphicons Halflings';
	src: url('../../fonts/glyphicons-halflings-regular.eot');
	src: url('../../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../../fonts/glyphicons-halflings-regular.woff2') format('woff2'), url('../../fonts/glyphicons-halflings-regular.woff') format('woff'), url('../../fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('../../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}
.glyphicon {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'Glyphicons Halflings';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.glyphicon-asterisk:before {
	content: "\002a";
}
.glyphicon-plus:before {
	content: "\002b";
}
.glyphicon-euro:before,
.glyphicon-eur:before {
	content: "\20ac";
}
.glyphicon-minus:before {
	content: "\2212";
}
.glyphicon-cloud:before {
	content: "\2601";
}
.glyphicon-envelope:before {
	content: "\2709";
}
.glyphicon-pencil:before {
	content: "\270f";
}
.glyphicon-glass:before {
	content: "\e001";
}
.glyphicon-music:before {
	content: "\e002";
}
.glyphicon-search:before {
	content: "\e003";
}
.glyphicon-heart:before {
	content: "\e005";
}
.glyphicon-star:before {
	content: "\e006";
}
.glyphicon-star-empty:before {
	content: "\e007";
}
.glyphicon-user:before {
	content: "\e008";
}
.glyphicon-film:before {
	content: "\e009";
}
.glyphicon-th-large:before {
	content: "\e010";
}
.glyphicon-th:before {
	content: "\e011";
}
.glyphicon-th-list:before {
	content: "\e012";
}
.glyphicon-ok:before {
	content: "\e013";
}
.glyphicon-remove:before {
	content: "\e014";
}
.glyphicon-zoom-in:before {
	content: "\e015";
}
.glyphicon-zoom-out:before {
	content: "\e016";
}
.glyphicon-off:before {
	content: "\e017";
}
.glyphicon-signal:before {
	content: "\e018";
}
.glyphicon-cog:before {
	content: "\e019";
}
.glyphicon-trash:before {
	content: "\e020";
}
.glyphicon-home:before {
	content: "\e021";
}
.glyphicon-file:before {
	content: "\e022";
}
.glyphicon-time:before {
	content: "\e023";
}
.glyphicon-road:before {
	content: "\e024";
}
.glyphicon-download-alt:before {
	content: "\e025";
}
.glyphicon-download:before {
	content: "\e026";
}
.glyphicon-upload:before {
	content: "\e027";
}
.glyphicon-inbox:before {
	content: "\e028";
}
.glyphicon-play-circle:before {
	content: "\e029";
}
.glyphicon-repeat:before {
	content: "\e030";
}
.glyphicon-refresh:before {
	content: "\e031";
}
.glyphicon-list-alt:before {
	content: "\e032";
}
.glyphicon-lock:before {
	content: "\e033";
}
.glyphicon-flag:before {
	content: "\e034";
}
.glyphicon-headphones:before {
	content: "\e035";
}
.glyphicon-volume-off:before {
	content: "\e036";
}
.glyphicon-volume-down:before {
	content: "\e037";
}
.glyphicon-volume-up:before {
	content: "\e038";
}
.glyphicon-qrcode:before {
	content: "\e039";
}
.glyphicon-barcode:before {
	content: "\e040";
}
.glyphicon-tag:before {
	content: "\e041";
}
.glyphicon-tags:before {
	content: "\e042";
}
.glyphicon-book:before {
	content: "\e043";
}
.glyphicon-bookmark:before {
	content: "\e044";
}
.glyphicon-print:before {
	content: "\e045";
}
.glyphicon-camera:before {
	content: "\e046";
}
.glyphicon-font:before {
	content: "\e047";
}
.glyphicon-bold:before {
	content: "\e048";
}
.glyphicon-italic:before {
	content: "\e049";
}
.glyphicon-text-height:before {
	content: "\e050";
}
.glyphicon-text-width:before {
	content: "\e051";
}
.glyphicon-align-left:before {
	content: "\e052";
}
.glyphicon-align-center:before {
	content: "\e053";
}
.glyphicon-align-right:before {
	content: "\e054";
}
.glyphicon-align-justify:before {
	content: "\e055";
}
.glyphicon-list:before {
	content: "\e056";
}
.glyphicon-indent-left:before {
	content: "\e057";
}
.glyphicon-indent-right:before {
	content: "\e058";
}
.glyphicon-facetime-video:before {
	content: "\e059";
}
.glyphicon-picture:before {
	content: "\e060";
}
.glyphicon-map-marker:before {
	content: "\e062";
}
.glyphicon-adjust:before {
	content: "\e063";
}
.glyphicon-tint:before {
	content: "\e064";
}
.glyphicon-edit:before {
	content: "\e065";
}
.glyphicon-share:before {
	content: "\e066";
}
.glyphicon-check:before {
	content: "\e067";
}
.glyphicon-move:before {
	content: "\e068";
}
.glyphicon-step-backward:before {
	content: "\e069";
}
.glyphicon-fast-backward:before {
	content: "\e070";
}
.glyphicon-backward:before {
	content: "\e071";
}
.glyphicon-play:before {
	content: "\e072";
}
.glyphicon-pause:before {
	content: "\e073";
}
.glyphicon-stop:before {
	content: "\e074";
}
.glyphicon-forward:before {
	content: "\e075";
}
.glyphicon-fast-forward:before {
	content: "\e076";
}
.glyphicon-step-forward:before {
	content: "\e077";
}
.glyphicon-eject:before {
	content: "\e078";
}
.glyphicon-chevron-left:before {
	content: "\e079";
}
.glyphicon-chevron-right:before {
	content: "\e080";
}
.glyphicon-plus-sign:before {
	content: "\e081";
}
.glyphicon-minus-sign:before {
	content: "\e082";
}
.glyphicon-remove-sign:before {
	content: "\e083";
}
.glyphicon-ok-sign:before {
	content: "\e084";
}
.glyphicon-question-sign:before {
	content: "\e085";
}
.glyphicon-info-sign:before {
	content: "\e086";
}
.glyphicon-screenshot:before {
	content: "\e087";
}
.glyphicon-remove-circle:before {
	content: "\e088";
}
.glyphicon-ok-circle:before {
	content: "\e089";
}
.glyphicon-ban-circle:before {
	content: "\e090";
}
.glyphicon-arrow-left:before {
	content: "\e091";
}
.glyphicon-arrow-right:before {
	content: "\e092";
}
.glyphicon-arrow-up:before {
	content: "\e093";
}
.glyphicon-arrow-down:before {
	content: "\e094";
}
.glyphicon-share-alt:before {
	content: "\e095";
}
.glyphicon-resize-full:before {
	content: "\e096";
}
.glyphicon-resize-small:before {
	content: "\e097";
}
.glyphicon-exclamation-sign:before {
	content: "\e101";
}
.glyphicon-gift:before {
	content: "\e102";
}
.glyphicon-leaf:before {
	content: "\e103";
}
.glyphicon-fire:before {
	content: "\e104";
}
.glyphicon-eye-open:before {
	content: "\e105";
}
.glyphicon-eye-close:before {
	content: "\e106";
}
.glyphicon-warning-sign:before {
	content: "\e107";
}
.glyphicon-plane:before {
	content: "\e108";
}
.glyphicon-calendar:before {
	content: "\e109";
}
.glyphicon-random:before {
	content: "\e110";
}
.glyphicon-comment:before {
	content: "\e111";
}
.glyphicon-magnet:before {
	content: "\e112";
}
.glyphicon-chevron-up:before {
	content: "\e113";
}
.glyphicon-chevron-down:before {
	content: "\e114";
}
.glyphicon-retweet:before {
	content: "\e115";
}
.glyphicon-shopping-cart:before {
	content: "\e116";
}
.glyphicon-folder-close:before {
	content: "\e117";
}
.glyphicon-folder-open:before {
	content: "\e118";
}
.glyphicon-resize-vertical:before {
	content: "\e119";
}
.glyphicon-resize-horizontal:before {
	content: "\e120";
}
.glyphicon-hdd:before {
	content: "\e121";
}
.glyphicon-bullhorn:before {
	content: "\e122";
}
.glyphicon-bell:before {
	content: "\e123";
}
.glyphicon-certificate:before {
	content: "\e124";
}
.glyphicon-thumbs-up:before {
	content: "\e125";
}
.glyphicon-thumbs-down:before {
	content: "\e126";
}
.glyphicon-hand-right:before {
	content: "\e127";
}
.glyphicon-hand-left:before {
	content: "\e128";
}
.glyphicon-hand-up:before {
	content: "\e129";
}
.glyphicon-hand-down:before {
	content: "\e130";
}
.glyphicon-circle-arrow-right:before {
	content: "\e131";
}
.glyphicon-circle-arrow-left:before {
	content: "\e132";
}
.glyphicon-circle-arrow-up:before {
	content: "\e133";
}
.glyphicon-circle-arrow-down:before {
	content: "\e134";
}
.glyphicon-globe:before {
	content: "\e135";
}
.glyphicon-wrench:before {
	content: "\e136";
}
.glyphicon-tasks:before {
	content: "\e137";
}
.glyphicon-filter:before {
	content: "\e138";
}
.glyphicon-briefcase:before {
	content: "\e139";
}
.glyphicon-fullscreen:before {
	content: "\e140";
}
.glyphicon-dashboard:before {
	content: "\e141";
}
.glyphicon-paperclip:before {
	content: "\e142";
}
.glyphicon-heart-empty:before {
	content: "\e143";
}
.glyphicon-link:before {
	content: "\e144";
}
.glyphicon-phone:before {
	content: "\e145";
}
.glyphicon-pushpin:before {
	content: "\e146";
}
.glyphicon-usd:before {
	content: "\e148";
}
.glyphicon-gbp:before {
	content: "\e149";
}
.glyphicon-sort:before {
	content: "\e150";
}
.glyphicon-sort-by-alphabet:before {
	content: "\e151";
}
.glyphicon-sort-by-alphabet-alt:before {
	content: "\e152";
}
.glyphicon-sort-by-order:before {
	content: "\e153";
}
.glyphicon-sort-by-order-alt:before {
	content: "\e154";
}
.glyphicon-sort-by-attributes:before {
	content: "\e155";
}
.glyphicon-sort-by-attributes-alt:before {
	content: "\e156";
}
.glyphicon-unchecked:before {
	content: "\e157";
}
.glyphicon-expand:before {
	content: "\e158";
}
.glyphicon-collapse-down:before {
	content: "\e159";
}
.glyphicon-collapse-up:before {
	content: "\e160";
}
.glyphicon-log-in:before {
	content: "\e161";
}
.glyphicon-flash:before {
	content: "\e162";
}
.glyphicon-log-out:before {
	content: "\e163";
}
.glyphicon-new-window:before {
	content: "\e164";
}
.glyphicon-record:before {
	content: "\e165";
}
.glyphicon-save:before {
	content: "\e166";
}
.glyphicon-open:before {
	content: "\e167";
}
.glyphicon-saved:before {
	content: "\e168";
}
.glyphicon-import:before {
	content: "\e169";
}
.glyphicon-export:before {
	content: "\e170";
}
.glyphicon-send:before {
	content: "\e171";
}
.glyphicon-floppy-disk:before {
	content: "\e172";
}
.glyphicon-floppy-saved:before {
	content: "\e173";
}
.glyphicon-floppy-remove:before {
	content: "\e174";
}
.glyphicon-floppy-save:before {
	content: "\e175";
}
.glyphicon-floppy-open:before {
	content: "\e176";
}
.glyphicon-credit-card:before {
	content: "\e177";
}
.glyphicon-transfer:before {
	content: "\e178";
}
.glyphicon-cutlery:before {
	content: "\e179";
}
.glyphicon-header:before {
	content: "\e180";
}
.glyphicon-compressed:before {
	content: "\e181";
}
.glyphicon-earphone:before {
	content: "\e182";
}
.glyphicon-phone-alt:before {
	content: "\e183";
}
.glyphicon-tower:before {
	content: "\e184";
}
.glyphicon-stats:before {
	content: "\e185";
}
.glyphicon-sd-video:before {
	content: "\e186";
}
.glyphicon-hd-video:before {
	content: "\e187";
}
.glyphicon-subtitles:before {
	content: "\e188";
}
.glyphicon-sound-stereo:before {
	content: "\e189";
}
.glyphicon-sound-dolby:before {
	content: "\e190";
}
.glyphicon-sound-5-1:before {
	content: "\e191";
}
.glyphicon-sound-6-1:before {
	content: "\e192";
}
.glyphicon-sound-7-1:before {
	content: "\e193";
}
.glyphicon-copyright-mark:before {
	content: "\e194";
}
.glyphicon-registration-mark:before {
	content: "\e195";
}
.glyphicon-cloud-download:before {
	content: "\e197";
}
.glyphicon-cloud-upload:before {
	content: "\e198";
}
.glyphicon-tree-conifer:before {
	content: "\e199";
}
.glyphicon-tree-deciduous:before {
	content: "\e200";
}
.glyphicon-cd:before {
	content: "\e201";
}
.glyphicon-save-file:before {
	content: "\e202";
}
.glyphicon-open-file:before {
	content: "\e203";
}
.glyphicon-level-up:before {
	content: "\e204";
}
.glyphicon-copy:before {
	content: "\e205";
}
.glyphicon-paste:before {
	content: "\e206";
}
.glyphicon-alert:before {
	content: "\e209";
}
.glyphicon-equalizer:before {
	content: "\e210";
}
.glyphicon-king:before {
	content: "\e211";
}
.glyphicon-queen:before {
	content: "\e212";
}
.glyphicon-pawn:before {
	content: "\e213";
}
.glyphicon-bishop:before {
	content: "\e214";
}
.glyphicon-knight:before {
	content: "\e215";
}
.glyphicon-baby-formula:before {
	content: "\e216";
}
.glyphicon-tent:before {
	content: "\26fa";
}
.glyphicon-blackboard:before {
	content: "\e218";
}
.glyphicon-bed:before {
	content: "\e219";
}
.glyphicon-apple:before {
	content: "\f8ff";
}
.glyphicon-erase:before {
	content: "\e221";
}
.glyphicon-hourglass:before {
	content: "\231b";
}
.glyphicon-lamp:before {
	content: "\e223";
}
.glyphicon-duplicate:before {
	content: "\e224";
}
.glyphicon-piggy-bank:before {
	content: "\e225";
}
.glyphicon-scissors:before {
	content: "\e226";
}
.glyphicon-bitcoin:before {
	content: "\e227";
}
.glyphicon-btc:before {
	content: "\e227";
}
.glyphicon-xbt:before {
	content: "\e227";
}
.glyphicon-yen:before {
	content: "\00a5";
}
.glyphicon-jpy:before {
	content: "\00a5";
}
.glyphicon-ruble:before {
	content: "\20bd";
}
.glyphicon-rub:before {
	content: "\20bd";
}
.glyphicon-scale:before {
	content: "\e230";
}
.glyphicon-ice-lolly:before {
	content: "\e231";
}
.glyphicon-ice-lolly-tasted:before {
	content: "\e232";
}
.glyphicon-education:before {
	content: "\e233";
}
.glyphicon-option-horizontal:before {
	content: "\e234";
}
.glyphicon-option-vertical:before {
	content: "\e235";
}
.glyphicon-menu-hamburger:before {
	content: "\e236";
}
.glyphicon-modal-window:before {
	content: "\e237";
}
.glyphicon-oil:before {
	content: "\e238";
}
.glyphicon-grain:before {
	content: "\e239";
}
.glyphicon-sunglasses:before {
	content: "\e240";
}
.glyphicon-text-size:before {
	content: "\e241";
}
.glyphicon-text-color:before {
	content: "\e242";
}
.glyphicon-text-background:before {
	content: "\e243";
}
.glyphicon-object-align-top:before {
	content: "\e244";
}
.glyphicon-object-align-bottom:before {
	content: "\e245";
}
.glyphicon-object-align-horizontal:before {
	content: "\e246";
}
.glyphicon-object-align-left:before {
	content: "\e247";
}
.glyphicon-object-align-vertical:before {
	content: "\e248";
}
.glyphicon-object-align-right:before {
	content: "\e249";
}
.glyphicon-triangle-right:before {
	content: "\e250";
}
.glyphicon-triangle-left:before {
	content: "\e251";
}
.glyphicon-triangle-bottom:before {
	content: "\e252";
}
.glyphicon-triangle-top:before {
	content: "\e253";
}
.glyphicon-console:before {
	content: "\e254";
}
.glyphicon-superscript:before {
	content: "\e255";
}
.glyphicon-subscript:before {
	content: "\e256";
}
.glyphicon-menu-left:before {
	content: "\e257";
}
.glyphicon-menu-right:before {
	content: "\e258";
}
.glyphicon-menu-down:before {
	content: "\e259";
}
.glyphicon-menu-up:before {
	content: "\e260";
}


@font-face {
	font-family: "startui";
	src:url("../../fonts/startui.eot");
	src:url("../../fonts/startui.eot?#iefix") format("embedded-opentype"),
	url("../../fonts/startui.woff") format("woff"),
	url("../../fonts/startui.ttf") format("truetype"),
	url("../../fonts/startui.svg#startui") format("svg");
	font-weight: normal;
	font-style: normal;
}

.font-icon {}

.fontastic() {
	font-family: "startui" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: inherit;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	vertical-align: middle;
	position: relative;
	top: -.15em;
}

[data-icon]:before {
	content: attr(data-icon);
	.fontastic();
}

[class^="font-icon-"]:before,
[class*=" font-icon-"]:before {
	.fontastic();
}

.font-icon-alarm:before {
	content: "\62";
}
.font-icon-build:before {
	content: "\63";
}
.font-icon-burger:before {
	content: "\64";
}
.font-icon-calend:before {
	content: "\65";
}
.font-icon-cart:before {
	content: "\66";
}
.font-icon-cogwheel:before {
	content: "\67";
}
.font-icon-comments:before {
	content: "\68";
}
.font-icon-contacts:before {
	content: "\69";
}
.font-icon-dashboard:before {
	content: "\6a";
}
.font-icon-dots:before {
	content: "\6b";
}
.font-icon-expand:before {
	content: "\6c";
}
.font-icon-filter:before {
	content: "\6d";
}
.font-icon-help:before {
	content: "\6e";
}
.font-icon-home:before {
	content: "\6f";
}
.font-icon-page:before {
	content: "\70";
}
.font-icon-pencil:before {
	content: "\71";
}
.font-icon-re:before {
	content: "\72";
}
.font-icon-rollup:before {
	content: "\73";
}
.font-icon-search:before {
	content: "\74";
}
.font-icon-share:before {
	content: "\75";
}
.font-icon-star:before {
	content: "\76";
}
.font-icon-users:before {
	content: "\77";
}
.font-icon-wallet:before {
	content: "\78";
}
.font-icon-minus:before {
	content: "\79";
}
.font-icon-pencil-thin:before {
	content: "\7a";
}
.font-icon-close:before {
	content: "\41";
}
.font-icon-speed:before {
	content: "\61";
}
.font-icon-menu-addl:before {
	content: "\42";
}
.font-icon-search:before {
	content: "\74";
}
.font-icon-edit:before {
	content: "\43";
}
.font-icon-chart:before {
	content: "\44";
}
.font-icon-zigzag:before {
	content: "\45";
}
.font-icon-tablet:before {
	content: "\46";
}
.font-icon-widget:before {
	content: "\47";
}
.font-icon-map:before {
	content: "\48";
}
.font-icon-chart-2:before {
	content: "\49";
}
.font-icon-doc:before {
	content: "\4a";
}
.font-icon-question:before {
	content: "\4b";
}
.font-icon-user:before {
	content: "\4c";
}
.font-icon-notebook:before {
	content: "\4d";
}
.font-icon-mail:before {
	content: "\4e";
}
.font-icon-close-2:before {
	content: "\4f";
}
.font-icon-pen:before {
	content: "\50";
}
.font-icon-arrow-down:before {
	content: "\51";
}
.font-icon-arrow-left:before {
	content: "\52";
}
.font-icon-arrow-right:before {
	content: "\53";
}
.font-icon-arrow-top:before {
	content: "\54";
}
.font-icon-check-circle:before {
	content: "\55";
}
.font-icon-cam-photo:before {
	content: "\56";
}
.font-icon-cam-video:before {
	content: "\57";
}
.font-icon-sound:before {
	content: "\58";
}
.font-icon-earth:before {
	content: "\59";
}
.font-icon-mail-2:before {
	content: "\5a";
}
.font-icon-upload:before {
	content: "\30";
}
.font-icon-dropbox:before {
	content: "\31";
}
.font-icon-google-drive:before {
	content: "\32";
}
.font-icon-yandex-disk:before {
	content: "\33";
}
.font-icon-box:before {
	content: "\34";
}
.font-icon-arrow-square-down:before {
	content: "\35";
}
.font-icon-refresh:before {
	content: "\36";
}
.font-icon-list-square:before {
	content: "\37";
}
.font-icon-list-rotate:before {
	content: "\38";
}
.font-icon-download:before {
	content: "\39";
}
.font-icon-heart:before {
	content: "\21";
}
.font-icon-check-bird:before {
	content: "\22";
}
.font-icon-clock:before {
	content: "\23";
}
.font-icon-trash:before {
	content: "\24";
}
.font-icon-circle-lined-error:before {
	content: "\25";
}
.font-icon-circle-lined-i:before {
	content: "\26";
}
.font-icon-circle-lined-smile:before {
	content: "\27";
}
.font-icon-circle-lined-success:before {
	content: "\28";
}
.font-icon-one-drive:before {
	content: "\29";
}
.font-icon-cloud-upload-2:before {
	content: "\2a";
}
.font-icon-plus:before {
	content: "\2b";
}
.font-icon-minus-1:before {
	content: "\2c";
}
.font-icon-arrow-square-up:before {
	content: "\2d";
}
.font-icon-revers:before {
	content: "\2e";
}
.font-icon-import:before {
	content: "\2f";
}
.font-icon-award:before {
	content: "\3a";
}
.font-icon-case:before {
	content: "\3b";
}
.font-icon-earth-bordered:before {
	content: "\3c";
}
.font-icon-comment:before {
	content: "\3d";
}
.font-icon-eye:before {
	content: "\3e";
}
.font-icon-fb-fill:before {
	content: "\3f";
}
.font-icon-in-fill:before {
	content: "\40";
}
.font-icon-lamp:before {
	content: "\5b";
}
.font-icon-picture:before {
	content: "\5d";
}
.font-icon-pdf-fill:before {
	content: "\5e";
}
.font-icon-notebook-bird:before {
	content: "\5f";
}
.font-icon-quote:before {
	content: "\60";
}
.font-icon-vk-fill:before {
	content: "\7b";
}
.font-icon-video-fill:before {
	content: "\7c";
}
.font-icon-tw-fill:before {
	content: "\7d";
}
.font-icon-answer:before {
	content: "\7e";
}
.font-icon-archive:before {
	content: "\5c";
}
.font-icon-case-2:before {
	content: "\e000";
}
.font-icon-clip:before {
	content: "\e001";
}
.font-icon-cloud:before {
	content: "\e002";
}
.font-icon-comments-2:before {
	content: "\e003";
}
.font-icon-del:before {
	content: "\e004";
}
.font-icon-event:before {
	content: "\e005";
}
.font-icon-download-3:before {
	content: "\e006";
}
.font-icon-download-2:before {
	content: "\e007";
}
.font-icon-dots-vert-square:before {
	content: "\e008";
}
.font-icon-fire:before {
	content: "\e009";
}
.font-icon-folder:before {
	content: "\e00a";
}
.font-icon-lock:before {
	content: "\e00b";
}
.font-icon-ok:before {
	content: "\e00c";
}
.font-icon-picture-2:before {
	content: "\e00d";
}
.font-icon-pin:before {
	content: "\e00e";
}
.font-icon-refresh-2:before {
	content: "\e00f";
}
.font-icon-view-cascade:before {
	content: "\e010";
}
.font-icon-users-group:before {
	content: "\e011";
}
.font-icon-upload-2:before {
	content: "\e012";
}
.font-icon-view-grid:before {
	content: "\e013";
}
.font-icon-view-rows:before {
	content: "\e014";
}
.font-icon-warning:before {
	content: "\e015";
}
.font-icon-facebook:before {
	content: "\e016";
}
.font-icon-instagram:before {
	content: "\e017";
}
.font-icon-google-plus:before {
	content: "\e018";
}
.font-icon-linkedin:before {
	content: "\e019";
}
.font-icon-twitter:before {
	content: "\e01a";
}
.font-icon-phone:before {
	content: "\e01b";
}
.font-icon-gp-fill:before {
	content: "\e01c";
}
.font-icon-ok-fill:before {
	content: "\e01d";
}
.font-icon-editor-align-center:before {
	content: "\e01e";
}
.font-icon-editor-align-justify:before {
	content: "\e01f";
}
.font-icon-editor-align-left:before {
	content: "\e020";
}
.font-icon-editor-align-right:before {
	content: "\e021";
}
.font-icon-editor-bold:before {
	content: "\e022";
}
.font-icon-editor-code:before {
	content: "\e023";
}
.font-icon-editor-eraser:before {
	content: "\e024";
}
.font-icon-editor-font:before {
	content: "\e025";
}
.font-icon-editor-fullscreen:before {
	content: "\e026";
}
.font-icon-editor-help:before {
	content: "\e027";
}
.font-icon-editor-img:before {
	content: "\e028";
}
.font-icon-editor-link:before {
	content: "\e029";
}
.font-icon-editor-list:before {
	content: "\e02a";
}
.font-icon-editor-magic:before {
	content: "\e02b";
}
.font-icon-editor-numeric-list:before {
	content: "\e02c";
}
.font-icon-editor-table:before {
	content: "\e02d";
}
.font-icon-editor-underline:before {
	content: "\e02e";
}
.font-icon-editor-video:before {
	content: "\e02f";
}
.font-icon-alarm-2:before {
	content: "\e030";
}
.font-icon-alarm-rotate:before {
	content: "\e031";
}
.font-icon-binoculars:before {
	content: "\e032";
}
.font-icon-cart-2:before {
	content: "\e033";
}
.font-icon-card:before {
	content: "\e034";
}
.font-icon-bookmark:before {
	content: "\e035";
}
.font-icon-chart-3:before {
	content: "\e036";
}
.font-icon-chart-4:before {
	content: "\e037";
}
.font-icon-check-square:before {
	content: "\e038";
}
.font-icon-del-circle:before {
	content: "\e039";
}
.font-icon-comp:before {
	content: "\e03a";
}
.font-icon-cloud-download:before {
	content: "\e03b";
}
.font-icon-downloaded:before {
	content: "\e03c";
}
.font-icon-link:before {
	content: "\e03d";
}
.font-icon-i-circle:before {
	content: "\e03e";
}
.font-icon-notebook-lines:before {
	content: "\e03f";
}
.font-icon-pdf:before {
	content: "\e040";
}
.font-icon-pen-square:before {
	content: "\e041";
}
.font-icon-play-prev:before {
	content: "\e042";
}
.font-icon-play-next:before {
	content: "\e043";
}
.font-icon-play-circle:before {
	content: "\e044";
}
.font-icon-play:before {
	content: "\e045";
}
.font-icon-pin-2:before {
	content: "\e046";
}
.font-icon-server:before {
	content: "\e047";
}
.font-icon-warning-circle:before {
	content: "\e048";
}
.font-icon-users-two:before {
	content: "\e049";
}
.font-icon-weather-cloud:before {
	content: "\e04a";
}
.font-icon-weather-cloud-circles:before {
	content: "\e04b";
}
.font-icon-weather-cloud-drops-lightning:before {
	content: "\e04c";
}
.font-icon-weather-cloud-moon:before {
	content: "\e04d";
}
.font-icon-weather-cloud-one-circle:before {
	content: "\e04e";
}
.font-icon-weather-cloud-one-drop:before {
	content: "\e04f";
}
.font-icon-weather-cloud-rain-snow:before {
	content: "\e050";
}
.font-icon-weather-cloud-sun:before {
	content: "\e051";
}
.font-icon-weather-cloud-two-circles:before {
	content: "\e052";
}
.font-icon-weather-cloud-two-drops:before {
	content: "\e053";
}
.font-icon-weather-cloud-two-snow:before {
	content: "\e054";
}
.font-icon-weather-clouds:before {
	content: "\e055";
}
.font-icon-weather-clound-lightning:before {
	content: "\e056";
}
.font-icon-weather-sun:before {
	content: "\e057";
}
.font-icon-weather-snowflake:before {
	content: "\e058";
}
.font-icon-weather-snow:before {
	content: "\e059";
}
.font-icon-weather-rain:before {
	content: "\e05a";
}
.font-icon-weather-one-snow:before {
	content: "\e05b";
}
.font-icon-weather-moon-small-cloud:before {
	content: "\e05c";
}
.font-icon-weather-moon-cloud-rain:before {
	content: "\e05d";
}
.font-icon-weather-moon-cloud:before {
	content: "\e05e";
}
.font-icon-weather-moon:before {
	content: "\e05f";
}
.font-icon-weather-lightning:before {
	content: "\e060";
}
.font-icon-weather-house-water:before {
	content: "\e061";
}
.font-icon-weather-funnel:before {
	content: "\e062";
}
.font-icon-weather-drop:before {
	content: "\e063";
}
.font-icon-weather-sun-cloud:before {
	content: "\e064";
}
.font-icon-weather-sun-clouds:before {
	content: "\e065";
}
.font-icon-weather-sun-rain:before {
	content: "\e066";
}
.font-icon-weather-thermometer:before {
	content: "\e067";
}
.font-icon-weather-umbrella:before {
	content: "\e068";
}
.font-icon-weather-waves:before {
	content: "\e069";
}
.font-icon-wp:before {
	content: "\e06a";
}
.font-icon-player-full-screen:before {
	content: "\e06b";
}
.font-icon-player-next:before {
	content: "\e06c";
}
.font-icon-player-settings:before {
	content: "\e06d";
}
.font-icon-player-sound:before {
	content: "\e06e";
}
.font-icon-player-subtitres:before {
	content: "\e06f";
}
.font-icon-player-wide-screen:before {
	content: "\e070";
}
.font-icon-case-3:before {
	content: "\e071";
}
.font-icon-github:before {
	content: "\e072";
}
.font-icon-learn:before {
	content: "\e073";
}
.font-icon-play-next-square:before {
	content: "\e074";
}
.font-icon-play-prev-square:before {
	content: "\e075";
}
.font-icon-play-square:before {
	content: "\e076";
}
.font-icon-picture-double:before {
	content: "\e077";
}
.font-icon-snippet:before {
	content: "\e078";
}
.font-icon-post:before {
	content: "\e079";
}
.font-icon-plus-1:before {
	content: "\e07a";
}



