@import '../partials/mixins';
@import '../partials/vars';

/* ==========================================================================
   Login
   ========================================================================== */
.sessions.new, .route_authentication.index.no-header {

     background-color: white !important;

}


.sign-box {

   width: 100%;
   max-width: 567px;
   margin: 0 auto;
   background: #fff;
   // border: solid 1px @color-border-light;
   border-radius: 5px;
   padding: 20px;
   font-size: 1rem /*16*/;
   position: relative;

   .sign-avatar {
      width: 100px;
      height: 100px;
      margin: 0 auto 10px;

      img {
         display: block;
         width: 100%;
         border-radius: 50%;
      }

      &.no-photo {
         border: solid 2px @color-border;
         text-align: center;
         border-radius: 50%;
         line-height: 96px;
         color: @color-border;
         font-size: 3.125rem /*50/16*/;
         font-weight: 700;
      }
   }

   .sign-title {
      font-size: 1.25rem /*20/16*/;
      text-align: center;
      margin: 0 0 15px;
      line-height: normal;
   }

   .btn {
      display: block;
      min-width: 108px;
      margin: 16px auto 12px;

      &.sign-up {
         margin-top: 18px;
      }
   }

   .sign-note {
      text-align: center;
   }

   a {
      .a_bordered_hover(@color-link);
   }

   .form-group {
      margin-bottom: 12px;
      .clearfix();
   }

   .checkbox {
      margin: 0;
      label {
         font-size: .875rem /*14/16*/;
      }
   }

   .reset {
      font-size: .875rem /*14/16*/;
   }

   .close {
      position: absolute;
      right: 10px; top: 4px;
      opacity: 1;
      color: @color-border;

      &:hover {
         color: @color-blue;
      }
   }
}

.sign-box.reset-password-box {
   .btn {
      display: inline-block !important;
      margin: 10px auto 12px;
      margin-right: 10px;
   }

   a {
      display: inline-block;
      margin-left: 4px;
   }
}

.input-login {
   border-width: 1px;
   box-shadow: none;
   border-radius: 5px!important;
   z-index: initial!important;
   border-color: #c0392b;
   font-weight: 300;
   height: 58px;
   padding: 10px 16px;
   margin-bottom: 20px;
   transition: border-color linear .5s;
   transition: box-shadow ease-out .3s;
   color: #555;
   margin-top: 6px;
}

.button-login {
   width: 100%;
   border-radius: 7px;
   background-color: #c0392b !important;
   border-color: #c0392b !important;
   padding: 15px;
   font-weight: bolder;
   :hover {
      background-color: #c05855 !important;
      border-color: #c0392b !important;
   }
}

.input-login-tml {
   border-width: 1px;
   box-shadow: none;
   border-radius: 5px!important;
   z-index: initial!important;
   border-color: #3f51b5;
   font-weight: 300;
   height: 58px;
   padding: 10px 16px;
   margin-bottom: 20px;
   transition: border-color linear .5s;
   transition: box-shadow ease-out .3s;
   color: #555;
   margin-top: 6px;
}

.button-login-tml {
   width: 100%;
   border-radius: 7px;
   background-color: #3f51b5 !important;
   border-color: #3f51b5 !important;
   padding: 15px;
   font-weight: bolder;
   :hover {
      background-color: #616fbfff !important;
      border-color: #3f51b5 !important;
   }
}