@import '../partials/reset';
@import '../partials/mixins';
@import '../partials/vars';

/* ==========================================================================
   Helper classes
   ========================================================================== */

/* Image replacement */
.ir { background-color: transparent; border: 0; overflow: hidden; *text-indent: -9999px; }
.ir:before { content: ""; display: block; width: 0; height: 150%; }

/* Hide from both screenreaders and browsers: h5bp.com/u */
.hidden { display: none !important; visibility: hidden; }

/* Hide only visually, but have it available for screenreaders: h5bp.com/v */
.visuallyhidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }

/* Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: h5bp.com/p */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus { clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }

/* Hide visually and from screenreaders, but maintain layout */
.invisible { visibility: hidden; }

/* Clear fix */
.clear { clear: both; height: 0px; font-size: 0px; line-height: 0px; overflow: hidden; }
.clearfix:before,
.clearfix:after { content: " "; display: table; }
.clearfix:after { clear: both; }
.clearfix { *zoom: 1; }

/* Align */
.text-center { text-align: center; }
.text-left { text-align: left; }
.text-right { text-align: right; }

/* Float */
.float-left { float: left; }
.float-right { float: right; }

// Table
.tbl { display: table; width: 100%; border-collapse: collapse; }
.tbl-row { display: table-row; }
.tbl-cell { display: table-cell; vertical-align: middle; }

// Borders
.no-border, .b-a-0 { border: none; }
.b-a { border: solid 1px @color-border-light; }
.b-l { border-left: solid 1px @color-border-light; }
.b-r { border-right: solid 1px @color-border-light; }
.b-t { border-top: solid 1px @color-border-light; }
.b-b { border-bottom: solid 1px @color-border-light; }
.b-t-0 { border-top: none; }
.b-r-0 { border-bottom: none; }
.border-primary { border-color: @color-blue; }
.border-default { border-color: @color-border-light; }
.border-warning { border-color: @color-gold; }
.border-success { border-color: @color-green; }
.border-info { border-color: @color-purple; }
.border-white { border-color: #fff; }
.border-danger { border-color: @color-red; }

// Border radius
.no-radius { border-radius: 0; }
.round { border-radius: .25rem; }
.brtl { border-top-left-radius: .25rem; }
.brtr { border-top-right-radius: .25rem; }
.blbl { border-bottom-left-radius: .25rem; }
.brbr { border-bottom-right-radius: .25rem; }

// Overflow
.overflow-hidden { overflow: hidden; }
.overflow-visible { overflow: visible; }
.overflow-auto { overflow: auto; }

// Fixed width
.w100 { width: 100px; }
.w150 { width: 150px; }
.w200 { width: 200px; }
.w250 { width: 250px; }
.w300 { width: 300px; }
.w350 { width: 350px; }
.w400 { width: 400px; }
.w450 { width: 450px; }
.w500 { width: 500px; }

// Positioning
.relative { position: relative; }
.absolute { position: absolute; }
.lt { left: 0; }
.rt { right: 0; }
.bt { bottom: 0; }
.tp { top: 0; }

// Other
.no-padding { padding: 0; }
.no-margin { margin: 0; }
.no-bg { background: none; }
.no-shadow  { box-shadow: none; }
.shadow { box-shadow: 1px 1px rgba(0,0,0,0.3); }
.text-shadow { text-shadow: 1px 1px rgba(0,0,0,0.3); }
.block  { display: block; }

// Margins
.m-x-n { margin-left: -15px; margin-right: -15px; }
.m-r-n { margin-right: -15px; }
.m-l-n-n { margin-left: -15px; }
.m-a-lg { margin: 30px; }
.m-r-lg { margin-right: 30px; }
.m-b-lg { margin-bottom: 30px; }
.m-l-lg { margin-left: 30px; }
.m-t-lg { margin-top: 30px; }
.m-x-lg { margin-left: 30px; margin-right: 30px; }
.m-y-lg { margin-top: 30px; margin-bottom: 30px; }
.m-a-md { margin: 20px; }
.m-r-md { margin-right: 20px; }
.m-b-md { margin-bottom: 20px; }
.m-l-md { margin-left: 20px; }
.m-t-md { margin-top: 20px; }
.m-x-md { margin-left: 20px; margin-right: 20px; }
.m-y-md { margin-top: 20px; margin-bottom: 20px; }
.m-a { margin: 15px; }
.m-r { margin-right: 15px; }
.m-b { margin-bottom: 15px; }
.m-l { margin-left: 15px; }
.m-t { margin-top: 15px; }
.m-x { margin-left: 15px; margin-right: 15px; }
.m-y { margin-top: 15px; margin-bottom: 15px; }
.m-a-0 { margin: 0; }
.m-r-0 { margin-right: 0; }
.m-b-0 { margin-bottom: 0; }
.m-l-0 { margin-left: 0; }
.m-t-0 { margin-top: 0; }
.m-x-0 { margin-left: 0; margin-right: 0; }
.m-y-0 { margin-top: 0; margin-bottom: 0; }
.m-x-auto { margin-right: auto; margin-left: auto; }

// Paddings
.p-x-n { padding-left: -15px; padding-right: -15px; }
.p-r-n { padding-right: -15px; }
.p-l-n-n { padding-left: -15px; }
.p-a-lg { padding: 30px; }
.p-r-lg { padding-right: 30px; }
.p-b-lg { padding-bottom: 30px; }
.p-l-lg { padding-left: 30px; }
.p-t-lg { padding-top: 30px; }
.p-x-lg { padding-left: 30px; padding-right: 30px; }
.p-y-lg { padding-top: 30px; padding-bottom: 30px; }
.p-a-md { padding: 20px; }
.p-r-md { padding-right: 20px; }
.p-b-md { padding-bottom: 20px; }
.p-l-md { padding-left: 20px; }
.p-t-md { padding-top: 20px; }
.p-x-md { padding-left: 20px; padding-right: 20px; }
.p-y-md { padding-top: 20px; padding-bottom: 20px; }
.p-a { padding: 15px; }
.p-r { padding-right: 15px; }
.p-b { padding-bottom: 15px; }
.p-l { padding-left: 15px; }
.p-t { padding-top: 15px; }
.p-x { padding-left: 15px; padding-right: 15px; }
.p-y { padding-top: 15px; padding-bottom: 15px; }
.p-a-0 { padding: 0; }
.p-r-0 { padding-right: 0; }
.p-b-0 { padding-bottom: 0; }
.p-l-0 { padding-left: 0; }
.p-t-0 { padding-top: 0; }
.p-x-0 { padding-left: 0; padding-right: 0; }
.p-y-0 { padding-top: 0; padding-bottom: 0; }
.p-x-auto { padding-right: auto; padding-left: auto; }

.display-table {
  display: table;
  width: 100%;
}

.text-vertical-middle {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.inline-block {
  display: inline-block !important;
}