@import '../partials/mixins';
@import '../partials/vars';

.action-btn.font-icon {
  vertical-align: middle;
  color: @color-grey;
  cursor: pointer;

  &:hover {
    color: @color-blue;
  }
}
