@import '../partials/mixins';
@import '../partials/vars';

/* ==========================================================================
   Chat
   ========================================================================== */

// Анимация ручки
@keyframes writing {
   0%   { left: 0px; }
   100% { left: 6px; }
}

// Чат
.chat-container { .clearfix(); }
.chat-list { float: left; width: 300px; position: relative; z-index: 2; }
.chat-area { float: right; width: 100%; margin-left: -300px; }
.chat-area-in { margin-left: 300px; border-left: solid 1px @color-border-light; }
@media (max-width: @sm-max-width) {
   .chat-list { float: none; width: auto; }
   .chat-area { float: none; width: auto; margin-left: 0; }
   .chat-area-in { margin-left: 0; border-left: none; }
}

// Dialogs list
.chat-list-search { height: 61px; border-bottom: solid 1px @color-border-light; padding: 13px 15px;
   .form-control { height: 34px; padding: 8px 15px; }
}

.chat-list-item { display: block; padding: 11px 15px 11px 56px; position: relative; font-size: .9375rem /*15/16*/; line-height: 20px; cursor: default; .hover_animate(); max-width: 100%; min-height: 52px;
   .chat-list-item-photo { position: absolute; left: 15px; top: 15px; width: 32px; height: 32px;
      img { display: block; width: 100%; height: 100%; border-radius: 50%; }
   }
   .chat-list-item-header { position: relative; padding: 0 45px 0 0; }
   .chat-list-item-name { display: inline-block; vertical-align: top; font-weight: 600; padding: 0 12px 0 0; position: relative; max-width: 100%;
      .name { display: inline-block; vertical-align: top; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; max-width: 100%; }
   }
   .chat-list-item-date { font-size: .875rem; position: absolute; right: 0; top: 0; color: @color-grey-blue-lighter-txt;  }

   .chat-list-item-cont { position: relative; padding: 0 45px 0 0; }
   .chat-list-item-txt { width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
      &.writing { color: @color-grey-blue-lighter-txt; }
      .icon { position: relative; display: inline-block; vertical-align: top; width: 20px; }
      .font-icon { color: @color-grey; position: absolute; left: 0; top: 0; animation-name: writing; animation-duration: 1.2s; animation-iteration-count: infinite; }
   }
   .chat-list-item-count,
   .chat-list-item-dot { position: absolute; right: 0; top: 50%; }
   .chat-list-item-count { height: 18px; line-height: 18px; padding: 0 4px; text-align: center; min-width: 18px; border-radius: 9px; color: #fff; font-weight: 600; margin-top: -9px; background: @color-red; font-size: .8125rem; }
   .chat-list-item-dot { width: 6px; height: 6px; margin-top: -3px; border-radius: 50%; background: @color-grey; }

   // Online
   &.online {
      .chat-list-item-name:before { content: ''; width: 6px; height: 6px; border-radius: 50%; display: block; position: absolute; right: 0; top: 50%; margin-top: -3px; background: @color-green; }
   }

   // Hover, selected
   .chat-list &:hover, &.selected { background-color: @color-bg-grey; }
}

@media (max-width: @sm-max-width) {
   .chat-list-in { height: 200px !important; border-bottom: solid 1px @color-border-light; }
}

// Dialog area
.chat-area-header { height: 62px; border-bottom: solid 1px @color-border-light; position: relative; padding: 0 120px 0 0;
   .chat-list-item { margin: -1px 0; }
   .chat-area-header-action { width: 120px; position: absolute; right: 15px; top: 0; padding: 20px 0; text-align: right; }
   .clean { text-align: center; line-height: 60px; font-weight: 600; font-size: 1rem; margin-right: -105px; }
}

.chat-area-bottom { padding: 15px; border-top: solid 1px @color-border-light; .clearfix();
   .caption, .btn { margin: 2px 12px 2px 0; }
   .caption { display: inline-block; padding: .375rem 0 .375rem 42px; font-size: 1rem; line-height: 1.5; text-align: center; vertical-align: middle; }
   .attach { float: right; position: relative; top: 11px; }
   .write-message { position: relative; padding: 0 0 0 42px;
      .form-group { margin-bottom: 10px; }
      .avatar { position: absolute; left: -3px; top: 0; width: 32px; height: 32px;
         img { display: block; width: 100%; height: 100%; border-radius: 50%; }
      }
   }

   @media (max-width: @lg-max-width) {
      .caption { padding-left: 0; }
   }
}

.chat-dialog-area { min-height: 200px;
   @media (max-width: @sm-max-width) { height: 200px !important; }
}

.chat-message { position: relative; padding: 11px 65px 11px 100px; font-size: .9375rem /*13/16*/; min-height: 52px; line-height: 20px;
   .chat-message-photo { position: absolute; left: 57px; top: 15px; width: 32px; height: 32px;
      img { display: block; width: 100%; height: 100%; border-radius: 50%; }
   }
   .chat-message-header { display: table; width: 100%;
      .tbl-cell { vertical-align: middle; }
      .tbl-cell-name { font-weight: 600; }
      .tbl-cell-date { color: @color-grey-blue-lighter-txt; text-align: right; white-space: nowrap; width: 10px; padding-left: 10px; font-size: .875rem; }
   }
   .chat-message-content { }
   .chat-message-txt { }
   .checkbox-bird { display: none; position: absolute; left: 21px; top: 22px; }

   &:hover, &.selected {
      .checkbox-bird { display: block; }
   }
   &.selected { background-color: @color-bg-grey; }

   // Quote
   &.quote { border-left: solid 4px @color-border-light; padding-left: 44px; padding-top: 0; padding-bottom: 0; padding-right: 0; min-height: 24px; margin: 10px 0 0;
      .chat-message-photo { width: 24px; height: 24px; left: 10px; top: 0;  }
      .chat-message-header { }
   }
}

.chat-dialog-clean { display: table; width: 100%; height: 100%; text-align: center;
   .chat-dialog-clean-in { display: table-cell; vertical-align: middle; padding: 10px 10px 25px; }
   .font-icon { color: @color-grey; font-size: 6rem; line-height: 1; }
   .caption { font-size: 1.25rem; font-weight: 600; margin: 0 0 .5rem 0; }
   .txt { color: @color-grey-blue-lighter-txt; max-width: 340px; margin: 0 auto; }
}


