@import '../partials/vars';

/* ==========================================================================
   Ribbons element
   ========================================================================== */

.left-corners() {
    &:after {
        position: absolute;
        left: -10px;
        top: 0px;
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 17px 0;
        border-top-color: transparent !important;
        border-right-color: @color-bg-blue;
        border-bottom-color: transparent !important;
        border-left-color: transparent !important;
    }
    &:before {
        position: absolute;
        left: -10px;
        bottom: 0px;
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0px 0px 17px 10px;
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: @color-bg-blue;
        border-left-color: transparent !important;
    }
}

.right-corners() {
    &:after {
        position: absolute;
        right: -10px;
        left: auto;
        top: 0px;
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 17px 10px 0px 0px;
        border-top-color: @color-bg-blue;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        border-left-color: transparent !important;
    }
    &:before {
        position: absolute;
        right: -10px;
        left: auto;
        bottom:  0px;
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 17px 0px 0px 10px;
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        border-left-color: @color-bg-blue;
    }
}

.ribbon {
    position: absolute;
    top: 10px;
    right: -1px;
    color: @color-white;
    background: @color-bg-blue;
    padding: 5px 12px 5px 9px;
    white-space: nowrap;
    &.right-top {
        top: 10px;
        bottom: auto;
        right: -1px;
        left: auto;
        .left-corners();
    }
    &.right-bottom {
        top: auto;
        bottom: 10px;
        right: -1px;
        left: auto;
        .left-corners();
    }
    &.left-top {
        padding: 5px 9px 5px 12px;
        top: 10px;
        bottom: auto;
        right: auto;
        left: -1px;
        .right-corners();
    }
    &.left-bottom {
        padding: 5px 9px 5px 12px;
        top: auto;
        bottom: 10px;
        right: auto;
        left: -1px;
        .right-corners();
    }
    &.green {
        background-color: @color-bg-green;
        &:before, &:after {
            border-color: @color-bg-green;
        }
    }
    &.purple {
        background-color: @color-bg-purple;
        &:before, &:after {
            border-color: @color-bg-purple;
        }
    }
    &.yellow {
        background-color: @color-bg-yellow;
        &:before, &:after {
            border-color: @color-bg-yellow;
        }
    }
    &.red {
        background-color: @color-bg-red;
        &:before, &:after {
            border-color: @color-bg-red;
        }
    }
    &.transparent {
        background-color: fade(@color-bg-white, 50%);
        &:before, &:after {
            border-color: fade(@color-bg-white, 50%);
        }
    }
}