/* surrounding tag container */
.tag-editor {
  list-style-type: none; padding: 5px; margin: 0; overflow: hidden; border: 1px solid #c5d6de; cursor: text;
  font-size: 1rem; color: #697782; background: #fff;
  border-radius: 4px;
}

/* core styles usually need no change */
.tag-editor li { display: block; float: left; overflow: hidden; margin: 3px 0; line-height: 1.5; }
.tag-editor div { float: left; padding: 0 4px; }
.tag-editor .placeholder { padding: 0 8px; color: #bbb; }
.tag-editor .tag-editor-spacer { padding: 0; width: 8px; overflow: hidden; color: transparent; background: none; }
.tag-editor input {
  vertical-align: inherit; border: 0; outline: none; padding: 0; margin: 0; cursor: text;
  font-family: inherit; font-weight: inherit; font-size: inherit; font-style: inherit;
  box-shadow: none; background: none;
}
/* hide original input field or textarea visually to allow tab navigation */
.tag-editor-hidden-src { position: absolute !important; left: -99999px; }
/* hide IE10 "clear field" X */
.tag-editor ::-ms-clear { display: none; }

/* tag style */
.tag-editor .tag-editor-tag {
  padding: 4px 0 4px 10px; color: #697782; background: #DBE4EB; white-space: nowrap;
  overflow: hidden; cursor: pointer; border-radius: 4px 0 0 4px;
}

/* delete icon */
.tag-editor .tag-editor-delete { background: #DBE4EB; cursor: pointer; padding: 4px 10px 4px 0; border-radius: 0 4px 4px 0; }
.tag-editor .tag-editor-delete i {
  display: inline-block; width: 7px; height: 7px; vertical-align: middle;
  position: relative; top: -3px;
  margin-left: 4px;
  margin-right: 2px;
  font-size: .650rem;
  color: #86929E;
}

.tag-editor .tag-editor-delete i:before {
  content: "\e004";
  font-family: startui!important;
  font-style: normal!important;
  font-weight: 400!important;
  font-variant: normal!important;
  text-transform: none!important;
  speak: none;
  line-height: inherit;
  top: -.15em;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  position: relative;
  vertical-align: middle;
}

.tag-editor .tag-editor-delete:hover i { color: #0689BF; }
.tag-editor .tag-editor-tag.active+.tag-editor-delete,
.tag-editor .tag-editor-tag.active+.tag-editor-delete i { display: none; cursor: text; }

.tag-editor .tag-editor-tag.active { background: none !important; }

/* jQuery UI autocomplete - code.jquery.com/ui/1.10.2/themes/smoothness/jquery-ui.css */
.ui-autocomplete { position: absolute; top: 0; left: 0; cursor: default; font-size: 14px; }
.ui-front { z-index: 9999; }
.ui-menu { list-style: none; padding: 1px; margin: 0; display: block; outline: none; }
.ui-menu .ui-menu-item a { text-decoration: none; display: block; padding: 2px .4em; line-height: 1.4; min-height: 0; /* support: IE7 */ }
.ui-widget-content { border: 1px solid #c5d6de; background: #fff; color: #555; }
.ui-widget-content a { color: #46799b; }
.ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus { background: #e0eaf1; }
.ui-helper-hidden-accessible { display: none; }
