@import '../partials/mixins';
@import '../partials/vars';

/* ==========================================================================
   Contacts
   ========================================================================== */

.contacts-page { .clearfix();
   .box-typical-header {
      .tbl-cell.tbl-cell-title {
            h1, h2, h3, h4, h5, h6 { display: inline; margin-right: 25px; }
      }
   }
   .contacts-tabs { display: inline-block; font-size: .9375rem /*15/16*/; font-weight: 600;
      .nav-item { display: inline-block; margin: 0 25px 0 0;
         &:last-child { margin-right: 0; }
      }
      a { display: block; color: @color-text; border-radius: 25rem; padding: 0 10px; line-height: 22px;
         &:hover { color: @color-blue; }
         &.active { color: #fff; background: @color-blue; }
      }
   }
}

.contacts-page-col-right { float: right; width: 280px; position: relative; }
.contacts-page-col-left { float: left; width: 100%; margin-right: -280px; }
.contacts-page-col-left-in { margin-right: 280px; border-right: solid 1px @color-border-light;
   .map { height: 300px; }
}

.contacts-page-section { border-top: solid 1px @color-border-light; padding: 15px; .clearfix();
   &:first-child { border-top: none; }
   .box-typical-header-sm { padding: 0 0 15px; }

   .contact { font-size: 1.125rem /*18/16*/; margin: 0 25px 10px 0;
      &:hover { opacity: .7; }
      .font-icon { vertical-align: middle; }
      .font-icon-facebook { color: @color-fb; }
      .font-icon-twitter { color: @color-tw; }
      .font-icon-linkedin { color: @color-in; }
      .font-icon-google-plus { color: @color-gp; }
      .font-icon-instagram { color: @color-inst; }
   }

   .contact-other { margin: 0 0 10px;
      .font-icon { vertical-align: middle; color: @color-grey; margin: 0 8px 0 0; }
   }
}

@media (max-width: 700px) {
   .contacts-page-col-right { float: none; width: auto; }
   .contacts-page-col-left { float: none; margin-right:0 }
   .contacts-page-col-left-in { margin-right: 0; border-right: none; }
}

/* ==========================================================================
   Google maps
   ========================================================================== */

.gm-style { font-family: @font-text; }

.infoBox { background: fade(@color-bg-dark, 95%); border-radius: 5px; color: #fff; padding: 5px 10px 5px 15px; font-size: 1rem; margin: 10px 0 0;
   &:before { content: ''; display: block; position: absolute; left: 50%; top: -10px; margin-left: -10px; width: 0; height: 0; border-style: solid; border-width: 0 10px 10px 10px; border-color: transparent transparent rgba(44,57,73,0.95) transparent; }
   >img { width: 8px; margin: 0 !important; position: absolute !important; right: 5px; top: 5px; opacity: .5;
      &:hover { opacity: 1; }
   }
   p { margin: .5rem 0; position: relative; padding: 0 0 0 26px; }
   .font-icon { position: absolute; left: 0; top: 2px; }
}

