@import '../partials/vars';

.daterangepicker {
  position: absolute;
  color: inherit;
  background: #fff;
  border-radius: 4px;
  width: 278px;
  padding: 4px;
  margin-top: 1px;
  top: 100px;
  left: 20px;
  /* Calendars */ }
.daterangepicker:before, .daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: @color-border-light;
  content: ''; }
.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid @color-border-light; }
.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent; }
.daterangepicker.opensleft:before {
  right: 9px; }
.daterangepicker.opensleft:after {
  right: 10px; }
.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto; }
.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto; }
.daterangepicker.opensright:before {
  left: 9px; }
.daterangepicker.opensright:after {
  left: 10px; }
.daterangepicker.dropup {
  margin-top: -5px; }
.daterangepicker.dropup:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid @color-border-light; }
.daterangepicker.dropup:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff; }
.daterangepicker.dropdown-menu {
  max-width: none;
  z-index: 3001; }
.daterangepicker.single .ranges, .daterangepicker.single .calendar {
  float: none; }
.daterangepicker.show-calendar .calendar {
  display: block; }
.daterangepicker .calendar {
  display: none;
  max-width: 270px;
  margin: 4px; }
.daterangepicker .calendar.single .calendar-table {
  border: none; }
.daterangepicker .calendar th, .daterangepicker .calendar td {
  white-space: nowrap;
  text-align: center;
  width: 32px;
  height: 28px;
  line-height: 28px;
}
.daterangepicker .calendar-table {
  padding: 4px;
  border-radius: 4px;
  background: #fff;
}

.daterangepicker table {
  width: 100%;
  margin: 0; }
.daterangepicker td, .daterangepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer; }
.daterangepicker td.available:hover, .daterangepicker th.available:hover {
  color: #01A6FF;
}
.daterangepicker td.week, .daterangepicker th.week {
  font-size: .8125rem;
  color: #8D99A9;
}
.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
  background-color: #fff;
  border-color: transparent;
  color: #8D99A9 }

.daterangepicker td.in-range {
  background-color: #E5F6FE;
  border-color: transparent;
  color: #000;
  border-radius: 0; }

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px; }
.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0; }
.daterangepicker td.start-date.end-date {
  border-radius: 4px; }
.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #01A6FF;
  border-color: transparent;
  color: #fff; }
.daterangepicker th.month {
  width: auto; }
.daterangepicker td.disabled, .daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through; }
.daterangepicker select.monthselect, .daterangepicker select.yearselect {
  font-size: 1rem;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default; }
.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%; }
.daterangepicker select.yearselect {
  width: 40%; }
.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
  width: 50px;
  margin-bottom: 0; }

.daterangepicker .input-mini {
  border: 1px solid @color-border-light;
  border-radius: 4px;
  color: #0A0A0A;
  display: block;
  margin: 0 0 5px 0;
  padding: .395rem 15px .355rem 30px;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5;
}


.daterangepicker .input-mini.active {
  border: 1px solid @color-border-light;
  border-radius: 4px; }
.daterangepicker .daterangepicker_input {
  position: relative; }
.daterangepicker .daterangepicker_input i {
  position: absolute;
  left: 8px;
  top: 8px; }
.daterangepicker .calendar-time {
  text-align: center;
  margin: 5px auto;
  line-height: 30px;
  position: relative;
  padding-left: 28px; }
.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed; }

.ranges {
  font-size: 1rem;
  float: none;
  margin: 4px;
  text-align: left; }
.ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%; }
.ranges li {
  font-size: 1rem;
  background: #DBE4EB;
  border-radius: 4px;
  color: #292B2A;
  padding: 8px 12px;
  margin-bottom: 8px;
  cursor: pointer; }
.ranges li:hover {
  background: #01A6FF;
  color: #fff;
}
.ranges li.active {
  background: #01A6FF;
  color: #fff; }

/*  Larger Screen Styling */
@media (min-width: 594px) {
  .daterangepicker {
    width: auto; }
  .daterangepicker .ranges ul {
    width: 160px; }
  .daterangepicker.single .ranges ul {
    width: 100%; }
  .daterangepicker.single .calendar.left {
    clear: none; }
  .daterangepicker.single .ranges, .daterangepicker.single .calendar {
    float: left; }
  .daterangepicker .calendar.left {
    clear: left;
    margin-right: 0; }
  .daterangepicker .calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .daterangepicker .calendar.right {
    margin-left: 0; }
  .daterangepicker .calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .daterangepicker .left .daterangepicker_input {
    padding-right: 12px; }
  .daterangepicker .calendar.left .calendar-table {
    padding-right: 12px; }
  .daterangepicker .ranges, .daterangepicker .calendar {
    float: left; } }

@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
    float: left; }
  .daterangepicker .calendar.left {
    clear: none; } }

.daterangepicker .fa {
  color: #909CA8;
}

.daterangepicker .daterangepicker_input .fa {
  font-size: 1rem;
}

.daterangepicker .daterangepicker_input .fa.fa-calendar {
  font-size: 1rem;
  position: absolute;
  top: 12px;
  left: 10px;
}


.daterangepicker .daterangepicker_input select,
.daterangepicker .table-condensed select {
  background: transparent;
  border: 1px solid @color-border-light;
  border-radius: 4px;
  padding: 4px;
  font-size: .875rem;
}

.daterangepicker .applyBtn, .cancelBtn {
  font-size: 1rem !important;
}

.daterangepicker .calendar-time {
  margin-top: 10px;
}

.daterangepicker .calendar-time .fa-clock-o {
  padding-left:1px;
}

.daterangepicker .table-condensed th.prev {
  vertical-align: middle !important;
}
