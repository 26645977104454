@font-face {
	font-family: 'Proxima Nova';
	src: url('../../fonts/Proxima_Nova_Regular.eot');
	src: url('../../fonts/Proxima_Nova_Regular.eot') format('embedded-opentype'),
	url('../../fonts/Proxima_Nova_Regular.woff2') format('woff2'),
	url('../../fonts/Proxima_Nova_Regular.woff') format('woff'),
	url('../../fonts/Proxima_Nova_Regular.ttf') format('truetype'),
	url('../../fonts/Proxima_Nova_Regular.svg#Proxima_Nova_Regular') format('svg');
	font-style: normal;
	font-weight: 400;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url('../../fonts/Proxima_Nova_Regular_Italic.eot');
	src: url('../../fonts/Proxima_Nova_Regular_Italic.eot') format('embedded-opentype'),
	url('../../fonts/Proxima_Nova_Regular_Italic.woff2') format('woff2'),
	url('../../fonts/Proxima_Nova_Regular_Italic.woff') format('woff'),
	url('../../fonts/Proxima_Nova_Regular_Italic.ttf') format('truetype'),
	url('../../fonts/Proxima_Nova_Regular_Italic.svg#Proxima_Nova_Regular_Italic') format('svg');
	font-style: italic;
	font-weight: 400;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url('../../fonts/Proxima_Nova_Bold.eot');
	src: url('../../fonts/Proxima_Nova_Bold.eot') format('embedded-opentype'),
	url('../../fonts/Proxima_Nova_Bold.woff2') format('woff2'),
	url('../../fonts/Proxima_Nova_Bold.woff') format('woff'),
	url('../../fonts/Proxima_Nova_Bold.ttf') format('truetype'),
	url('../../fonts/Proxima_Nova_Bold.svg#Proxima_Nova_Bold') format('svg');
	font-style: normal;
	font-weight: 700;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url('../../fonts/Proxima_Nova_Bold_Italic.eot');
	src: url('../../fonts/Proxima_Nova_Bold_Italic.eot') format('embedded-opentype'),
	url('../../fonts/Proxima_Nova_Bold_Italic.woff2') format('woff2'),
	url('../../fonts/Proxima_Nova_Bold_Italic.woff') format('woff'),
	url('../../fonts/Proxima_Nova_Bold_Italic.ttf') format('truetype'),
	url('../../fonts/Proxima_Nova_Bold_Italic.svg#Proxima_Nova_Bold_Italic') format('svg');
	font-style: italic;
	font-weight: 700;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url('../../fonts/Proxima_Nova_Semibold.eot');
	src: url('../../fonts/Proxima_Nova_Semibold.eot') format('embedded-opentype'),
	url('../../fonts/Proxima_Nova_Semibold.woff2') format('woff2'),
	url('../../fonts/Proxima_Nova_Semibold.woff') format('woff'),
	url('../../fonts/Proxima_Nova_Semibold.ttf') format('truetype'),
	url('../../fonts/Proxima_Nova_Semibold.svg#Proxima_Nova_Semibold') format('svg');
	font-style: normal;
	font-weight: 600;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url('../../fonts/Proxima_Nova_Semibold_Italic.eot');
	src: url('../../fonts/Proxima_Nova_Semibold_Italic.eot') format('embedded-opentype'),
	url('../../fonts/Proxima_Nova_Semibold_Italic.woff2') format('woff2'),
	url('../../fonts/Proxima_Nova_Semibold_Italic.woff') format('woff'),
	url('../../fonts/Proxima_Nova_Semibold_Italic.ttf') format('truetype'),
	url('../../fonts/Proxima_Nova_Semibold_Italic.svg#Proxima_Nova_Semibold_Italic') format('svg');
	font-style: italic;
	font-weight: 600;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url('../../fonts/Proxima_Nova_Light.eot');
	src: url('../../fonts/Proxima_Nova_Light.eot') format('embedded-opentype'),
	url('../../fonts/Proxima_Nova_Light.woff2') format('woff2'),
	url('../../fonts/Proxima_Nova_Light.woff') format('woff'),
	url('../../fonts/Proxima_Nova_Light.ttf') format('truetype'),
	url('../../fonts/Proxima_Nova_Light.svg#Proxima_Nova_Light') format('svg');
	font-style: normal;
	font-weight: 300;
}
@font-face {
	font-family: 'Proxima Nova';
	src: url('../../fonts/Proxima_Nova_Light_Italic.eot');
	src: url('../../fonts/Proxima_Nova_Light_Italic.eot') format('embedded-opentype'),
	url('../../fonts/Proxima_Nova_Light_Italic.woff2') format('woff2'),
	url('../../fonts/Proxima_Nova_Light_Italic.woff') format('woff'),
	url('../../fonts/Proxima_Nova_Light_Italic.ttf') format('truetype'),
	url('../../fonts/Proxima_Nova_Light_Italic.svg#Proxima_Nova_Light_Italic') format('svg');
	font-style: italic;
	font-weight: 300;
}
